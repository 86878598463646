/* Custom reset */

:root {
  --transition-base: 150ms ease-in-out;
  --color-primary: #65b7d7;
}

@layer reset, sirius-base, no-reset;

@layer reset {
  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-size: 16px;
  }

  ul,
  ol,
  li,
  dl,
  dt,
  dd {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  form {
    margin: 0;
  }

  *,
  ::before,
  ::after {
    border-width: 0;
    border-style: solid;
  }

  input,
  textarea,
  select,
  button {
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    border-radius: 0;
    color: inherit;
    border: none;
    background: transparent;
    font-size: inherit;
  }

  button {
    padding: 0;
    cursor: pointer;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
}

/* Base */

@layer sirius-base {
  body {
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
  }

  a {
    color: inherit;
    text-decoration: inherit;
    transition: var(--transition-base);
    transition-property: color;
    line-height: 1.2;
  }

  a:hover {
    color: var(--color-primary);
  }

  #portals {
    position: absolute;
    inset: 0;
    pointer-events: none;
  }

  #portals > * {
    pointer-events: auto;
  }
}

@layer no-reset {
  .no-reset * {
    all: revert;
  }
}

@media print {
  @page {
    margin: 2cm;
  }

  * {
    overflow: visible !important;
    white-space: normal !important;
  }

  .no-print {
    display: none !important;
  }

  [data-toast-container] {
    display: none !important;
  }

  /* Zendesk Widget */
  iframe#launcher {
    display: none !important;
  }
}

/* Turn off Writer.com extension */
qordoba-button,
qordoba-highlight,
qordoba-plugin,
qordoba-fixed-button,
qordoba-popups,
qordoba-inline-root,
qordoba-modal-root {
  display: none;
}

/* React Modal override */

.Overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 0.3s ease;
  opacity: 0;
  background-color: rgba(20, 28, 36, 0.8);
}

.Overlay-after-open {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.Overlay-before-close {
  opacity: 0;
}

.Modal {
  background: #fff;
  display: flex;
  flex-flow: column nowrap;
  max-width: 85vw;
  max-height: 85vw;
  width: 500px;
  transform-origin: center;
  transform: translateY(-20px);
  transition: transform 0.3s ease;
}

.Modal:focus {
  outline: none;
}

.Modal-after-open {
  transform: translateY(0);
}

.Modal-before-close {
  transform: translateY(-20px);
}

@keyframes x-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes x-ping {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes x-pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes x-bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animationtimingfunction: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animationtimingfunction: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.checkerboard-linear-gradient {
  background-image: linear-gradient(
      45deg,
      #efefef 25%,
      rgba(239, 239, 239, 0) 25%,
      rgba(239, 239, 239, 0) 75%,
      #efefef 75%,
      #efefef
    ),
    linear-gradient(
      45deg,
      #efefef 25%,
      rgba(239, 239, 239, 0) 25%,
      rgba(239, 239, 239, 0) 75%,
      #efefef 75%,
      #efefef
    );
  background-color: white;
  background-position:
    0 0,
    10px 10px;
  background-size: 21px 21px;
}

.revision-time-line:before {
  border-left: 2px solid;
  border-color: theme("colors.grey.border.DEFAULT");
  bottom: 0;
  content: "";
  display: block;
  left: 20px;
  position: absolute;
  top: 4px;
}

.revision-time-line:after {
  content: "";
  mask-image: url("@/assets/imgs/inline-svg/chevron-up.svg");
  position: absolute;
  mask-size: 22px;
  height: 22px;
  width: 22px;
  left: 10px;
  top: -6px;
  background-color: theme("colors.grey.border.DEFAULT");
}

.revision-default-icon:before {
  display: block;
  content: " ";
  line-height: 0;
  font-size: 0;
  width: 8px;
  height: 8px;
  background-color: theme("colors.dusk.bg.stronger");
  border-radius: 50%;
}

.revision-linear-gradient {
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 100%
  );
}

.background-grid {
  position: relative;
  /* colors.dusk.bg.strong with 0.7 opacity */
  background-color: rgba(9, 11, 15, 0.7);
}

.background-grid:before {
  content: " ";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.05;
  background-image: url("@/assets/imgs/background-grid.png");
  background-size: 32px 32px;
  position: absolute;
}

.active-layout-indicator {
  width: 8px;
  height: 8px;
  float: left;
  border-radius: 50%;
}

.active-layout-indicator[data-enabled="true"] {
  background-color: theme("colors.grey.bg.strong");
  box-shadow: 0 1px 0 #fff;
}

.active-layout-indicator[data-enabled="true"] {
  background-color: theme("colors.success.bg.strong");
  box-shadow: 0 0 0 2px rgb(94, 188, 213, 0.7);
}

.live-node-hyper-link a {
  color: theme("colors.primary.on.light");
  transition: 150ms ease-in-out;
  transition-property: color, box-shadow;
  cursor: pointer;
}

.live-node-hyper-link a:hover {
  color: theme("colors.primary.on.DEFAULT");
  text-decoration: underline;
}
.live-node-hyper-link a:focus {
  outline: none;
  color: theme("colors.primary.on.DEFAULT");
  /* colors.primary.border.DEFAULT with 0.7 opacity */
  box-shadow: 0 0 0 2px rgb(94, 188, 213, 0.7);
}

.status-item:not(:last-child):after {
  content: "|";
  margin-left: 8px;
}
/* TWO FACTOR STEPS */
.security-auth-inner-step {
  padding: 24px 16px;
  display: flex;
  gap: 16px;
  transition: color 0.35s 0.35s;
  overflow: hidden;
}

.security-auth-inner-step:not(:first-child) {
  border-top: 1px;
  border-style: solid;
  border-top-color: theme("colors.grey.bg.DEFAULT");
}
.security-auth-inner-step .StepIcon {
  transition: background-color 0.35s 0.35s;
}
.security-auth-inner-step > div:last-child > div {
  max-height: 300px;
  overflow: hidden;
  transition: max-height 0.35s 0.35s;
}
.security-auth-inner-step[data-hidden="true"] {
  max-height: 374px;
  transition:
    max-height 0.35s 0.35s,
    padding 0.35s 0.35s;
}
.security-auth-inner-step[data-disabled="true"] {
  color: theme("colors.grey.bg.DEFAULT");
  transition-delay: 0s;
}

.security-auth-inner-step[data-disabled="true"] .StepIcon {
  background-color: theme("colors.grey.bg.DEFAULT");
  transition-delay: 0s;
}
.security-auth-inner-step[data-disabled="true"][data-hidden="true"] {
  padding: 0px 24px;
  max-height: 0;
  transition-delay: 0s;
  border: none;
}
.security-auth-inner-step[data-disabled="true"] > div:last-child > div {
  max-height: 0;
  transition-delay: 0s;
}
