.rdp {
  --rdp-cell-size: 28px;
  --rdp-accent-color: theme(colors.primary.300) !important;
  --rdp-accent-light-color: theme(colors.primary.100) !important;
  --rdp-background-color: theme(colors.primary.50) !important;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #3003e1 !important;
  --rdp-background-color-dark: #180270 !important;
  /* Outline border for focused elements */
  --rdp-outline: 1px solid var(--rdp-accent-color) !important;
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 1px solid theme(colors.primary.600) !important;

  @apply m-0 select-none font-accent text-sm text-dusk-on;
}

.rdp-head_cell {
  text-transform: capitalize;
}

.rdp-caption {
  font-size: 0.875em;
}

.rdp-nav_icon {
  width: 0.75em;
  height: 0.75em;
}

/* Use :focus-visible instead of :focus */
.rdp-nav_button:focus {
  color: inherit;
  border: 0;
  background-color: transparent;
}

.rdp-nav_button:focus-visible {
  color: inherit;
  border: var(--rdp-outline);
  background-color: var(--rdp-background-color);
}

.rdp-nav_button:hover:not([aria-disabled="true"]) {
  background-color: var(--rdp-background-color);
}
/* --- */

.rdp.x-rdp-disabled .rdp-caption,
.rdp.x-rdp-disabled .rdp-head {
  opacity: theme(opacity.disabled);
}

.rdp-row {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.rdp-head_row {
  border: 0;
}

.rdp-caption_label {
  font-family: theme(fontFamily.accent);
  font-weight: 600;
  text-transform: uppercase;
  font-size: inherit;
}

.rdp-head {
  font-family: theme(fontFamily.accent);
  font-weight: 600;
  color: on-light;
}

.rdp-button {
  outline: 0;
}

.rdp-weeknumber,
.rdp-day {
  border-radius: theme(borderRadius.sm);
}

.rdp-day_outside {
  color: on-lighter;
}

.rdp-day_range_start {
  background-color: var(--rdp-accent-color);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: white;
}

.rdp-day_range_start.rdp-modifier-hover:hover {
  background-color: var(--rdp-accent-color);
}

.rdp-day_range_end {
  background-color: var(--rdp-accent-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: white;
}

.rdp-day_range_end.rdp-modifier-hover:hover {
  background-color: var(--rdp-accent-color);
}

.rdp-day_range_end.rdp-day_range_start {
  border-radius: theme(borderRadius.sm);
}

.rdp-day_range_middle {
  background-color: var(--rdp-accent-light-color);
  border-radius: 0;
  color: theme(colors.dusk.on.DEFAULT);
}

.rdp-day_range_middle.rdp-modifier-hover:hover {
  background-color: var(--rdp-accent-light-color);
}

.rdp-button[aria-disabled="true"] {
  opacity: theme(opacity.disabled);
}

/* "hover" modifier */
.rdp-button.rdp-modifier-hover:not([aria-disabled="true"]):not(
    .rdp-day_selected
  ):not(.rdp-day_range_middle):not(.rdp-day_range_start):not(
    .rdp-day_range_end
  ) {
  background-color: var(--rdp-background-color);
}

.rdp-day_today {
  border: 1px solid theme(colors.primary.border.strong);
}
