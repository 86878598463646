/* Custom reset */

:root {
  --color-login-bg: #f5f9fb;
}

@layer sirius-base {
  html,
  body {
    background: var(--color-login-bg);
    height: 100%;
  }

  #app {
    height: 100%;
  }
}
